/******
*** Vendors
*****/
global.jQuery = require('jquery');
global.$ = global.jQuery;
global.moment = require('moment');
global._ = require('lodash');

global.componentsPath = 'Content/views/components/';

/******
*** Modules Angulars
*****/

var angular = require('angular');
require('angular-animate');
require('angular-feature-flags');
require('ng-pickadate');
require('restangular');
// var noUiSlider = require('./vendors/nouislider.min.js');
require('./vendors/nouislider.js');
// var selectric = require('./vendors/jquery.selectric.min.js');
global.noUiSlider = require('nouislider');
global.slick = require('./vendors/slick.min');
require('nouislider-angular');
require('wnumb');
require('angular-promise-buttons');
require('./vendors/ng-scrollbar.min.js');
require('./vendors/re-tree.min');
require('./vendors/ng-device-detector');
require('./vendors/angular-validation.custom');
require('./vendors/angular.loading-bar.custom');
require('./vendors/jquery.maskedinput');
require('./vendors/jquery.inputmask');
/******
*** Libs dependencies
*****/
require('./vendors/what-input');
require('./vendors/pickadate/picker.js');
require('./vendors/pickadate/picker.date.js');
require('./vendors/pickadate/picker.time.js');
require('malihu-custom-scrollbar-plugin')($);
// var velocity = require('./vendors/velocity.min.js');
// require('./vendors/velocity.ui.min.js');
require('velocity-animate');
require('./vendors/ngMask.min.js');
var fundation = require('./vendors/foundation.min');

// es6 version
// import "./vendors/what-input";
// import fundation from './vendors/foundation.min'; // Custom build

if(window.navigator.userAgent.indexOf("MSIE ") !== -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {

	var svg4everybody = require('svg4everybody');
	svg4everybody({
		polyfill: true // polyfill <use> elements for External Content
	});

}

if(window.navigator.userAgent.indexOf("MSIE 9.0") !== -1) {

	$('html').addClass('lt-ie10');

}

$(document).ready(function(){

  var $body = $('body');

  $body.on('click', 'a[href="#"]', function(e){
	e.preventDefault();
  });

});
